import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import homeLogo from "../../assets/logo.png";
import homeLogo2 from "../../assets/logo2.png"
import Type from "../../components/Type";
import profile_pic_4 from "../../assets/profile_pic_4.JPG";
import sketchbook from "../../assets/sketchbook.png";
import bg_1 from "../../assets/bg_1.png";
import Divider from '@mui/material/Divider';
import AudioPlayer from "../../components/AudioPlayer";
import guitar from "../../assets/audio/guitar.mp3"
import StopMotionGallery from "../../components/StopMotionGallery";
import icon1 from "../../assets/home/1.png";
import icon2 from "../../assets/home/2.png";
import icon3 from "../../assets/home/3.png";
import icon4 from "../../assets/home/4.png";
import icon5 from "../../assets/home/icon5.png";


function Home() {
  
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [hoveredText, setHoveredText] = useState(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (text) => {
    setHoveredText(text);
  };

  const handleMouseLeave = () => {
    setHoveredText(null);
  };

  const handleMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  const content = {
    padding: '50px 0 0 0',
    display: 'grid',
    alignItems: 'center',
    textAlign: 'left',
    width: 250,
    height: 250,
    // fontSize: 'large'
  }

  const tooltipStyle = {
    position: 'absolute',
    top: cursorPos.y + 20, // 20px below the cursor
    left: cursorPos.x + 20, // 20px to the right of the cursor
    backgroundColor: 'white',
    padding: '5px',
    border: '1px solid black',
    zIndex: 1000,
    pointerEvents: 'none',
    fontSize: 'small',
    fontFamily: 'Deja Vu'
  };
  const header_text = {
    paddingTop: isMobile ? '35px' : '10px',
    paddingRight: isMobile ? '10px' : 0,
    fontSize: isMobile ? '1.5em' : '1.5em',
    fontWeight: 100,
    textAlign: 'left',
  }

  const divider_style = {
    py: 0,
    width: '100%',
    border: '0.5px solid',
    borderColor: 'black',
    position: 'absolute',
    top: '50vh',
    left: 0,
    opacity: 1,
  };

  const rvertical_divider_style = {
    opacity: 1,
    border: '0.5px solid',
    borderColor: "black",
    width: '0',
    height: '40vh',
    top: '0',
    right: '35vw',
    position: 'absolute',
  };
  const lvertical_divider_style = {
    opacity: 1,
    border: '0.5px solid',
    borderColor: "black",
    width: '0',
    height: '58vh',
    top: '50vh',
    left: '30vw',
    position: 'absolute',
  };
  const right_blurb = {
    textAlign: 'left',
    position: 'absolute',
    top: '52vh',
    left: '88vw',
    fontSize: isMobile ? '0.5em' : '1em'
  };

  const intro_blurb = {
    textAlign: 'justify',
    position: 'absolute',
    top: '110vh',
    left: isMobile ? '60vw' : '80vw',
    fontSize: isMobile ? '0.7em' : '1em',
    paddingRight: 25
  };

  const lists_style = {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    paddingTop: '51vh',
    textAlign: 'left'
  }

  const list_style = {
    height: '17vh',
    padding: '20px',
    whiteSpace: "nowrap" 
  }

  const flex_col = {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 0,
    padding: '2em',
  }

  const flex_col_center = {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 0,
    padding: '2em',

  }

  const flex_col_end = {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: 0,
    padding: '2em',
  }

  const headers_style = {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '45vh',
    
  }

  const container = {
    outlineColor: "transparent",
    textAlign: "left",
    padding: '2em',
  }

  const icon_style = {
    maxWidth: '40px',
  }
  
  const flex_row = {
    display: 'flex',
    flexDirection: 'row',
    // gap: '20px',
    alignItems: 'flex-start',
  }

  return (
    <section onMouseMove={handleMouseMove}>
      <Container fluid className="home-section" id="home" style={container}>
          <Col md={2} style={flex_col_center}>
          <div style={flex_row}>
            <div onMouseEnter={() => handleMouseEnter('radiohead')} onMouseLeave={handleMouseLeave}>
              <img src={icon2} style={{"height":"70px"}}></img>
            </div>
            <div id="radiohead" style={list_style}>
                <a href="/radiohead/education">education</a><br></br>
                {/* <a href="/radiohead/books">books</a><br></br> */}
                <a href="https://www.linkedin.com/in/sky-jung-4ab024190/" target="_">professional</a><br></br>
                {/* <a>poems</a><br></br> */}
            </div>
          </div>
            
          </Col>
          <Col md={2} style={flex_col}>
            <div style={flex_row}>
            <div onMouseEnter={() => handleMouseEnter('becoming miscellaneous')} onMouseLeave={handleMouseLeave}>
                <img src={icon1} style={{"width":"50px"}}></img>
              </div>
              <div id="becoming-misc" style={list_style}>
                  <a href="/softwareengineer">software engineer</a><br></br>
                  <a href="https://skyjung.substack.com" target="_">writer</a><br></br>
                  <a href="https://www.instagram.com/dainergirl/" target="_">etc</a>
              </div>
            </div>
          </Col>
          {/* <Divider orientation="vertical" style={lvertical_divider_style} flexItem/> */}
          <Col md={2} style={flex_col_end}>
          <div style={flex_row}>
          <div onMouseEnter={() => handleMouseEnter('keeping time')} onMouseLeave={handleMouseLeave}>
              <img src={icon3} style={{"height":"70px"}}></img></div>
              <div id="keeping-time" style={list_style}>
                    {/* <a href="/time/2024">2024</a><br></br>
                    <a href="/time/2023">2023</a><br></br>
                    <a href="/time/2011">2011</a><br></br>
                    <a href="/time/2010">2010</a><br></br>
                    <a href="/time/2008">2008</a> */}
              </div>
            </div>
          </Col>
          <Col md={2} style={flex_col_center}>
          <div style={flex_row}>
          <div onMouseEnter={() => handleMouseEnter('paraphernalia')} onMouseLeave={handleMouseLeave}>
              <img src={icon5} style={{"width":"120px"}}></img></div>
              <div id="paraphernalia" style={list_style}>
                  {/* <a href="https://skyjung.substack.com/" target="_blank">blog</a><br></br> */}
                  <a href="https://www.youtube.com/@skyjung" target="_blank">vlogs</a><br></br>
                  <a href="/paraphrenalia/photography">photography</a><br></br>
                  <a href="/artist">art</a><br></br>
                  <a href="/paraphrenalia/theater">theater</a><br></br>
              </div>
            </div>
          </Col>
          {/* <Divider orientation="vertical" style={rvertical_divider_style} flexItem/> */}
          <Col md={2} style={flex_col}>
          {/* <div id="airborne"><h1 style={header_text}>sky jung</h1></div> */}
          <span style={header_text} id="airborne">sky jung</span>
          <div style={flex_row}>
          <div onMouseEnter={() => handleMouseEnter('everyday objects')} onMouseLeave={handleMouseLeave}>
              <img src={icon4} style={{"width":"120px"}}></img></div>
            <div id="everyday-objects" style={list_style}>
                {/* <a>things</a><br></br>
                <a>people</a><br></br>
                <a>places</a> */}
            </div>
            </div>
          </Col>
          <Col md={2} style={flex_col}>
          
          </Col>
          {/* <Col md={8} style={lists_style}> */}
            
            
            
            <div style={right_blurb}>
              <p>los angeles, ca<br></br>
                  seoul, korea<br></br>
                  orange county, ca<br></br>
                  boston, ma<br></br>
                  —
              </p>
            </div>
            {hoveredText && (
              <div style={tooltipStyle}>
                {hoveredText}
              </div>
            )}
          {/* </Col> */}
          <Col>
          </Col>
          
      </Container>
      <Container fluid className="home-section" style={container}>

      </Container>
    </section>
    
  );
}

export default Home;
